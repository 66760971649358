<template>
  <v-scale-transition>
    <panel
      :breadcrumbs="breadcrumbs"
      :title="title"
    >
      <v-card>
        <v-toolbar card>
          <v-select
            v-model="year"
            :items="years"
            label="Year"
            prepend-inner-icon="event"
          />
          <v-spacer />
          <!-- <v-tooltip bottom>
            <v-btn
              slot="activator"
              icon
              @click="showChart = !showChart"
            >
              <v-icon>{{ showChart ? 'cancel' : 'mdi-chart-bar' }}</v-icon>
            </v-btn>
            <span>{{ showChart ? 'Hide chart' : 'Show chart' }}</span>
          </v-tooltip> -->
        </v-toolbar>
        <!-- <v-slide-y-transition>
          <v-card-text v-show="showChart">
            <chart
              :options="chartOptions"
              :series="series"
              type="bar"
            />
          </v-card-text>
        </v-slide-y-transition> -->
        <v-data-table
          :headers="headers"
          :items="items"
          hide-actions
          class="mt-3 elevation-1"
        >
          <template #items="props">
            <tr>
              <td class="text-xs-center">
                {{ props.item.month }}
              </td>
              <td class="text-xs-center info">
                {{ props.item.dealer }}
              </td>
              <td class="text-xs-center info">
                {{ props.item.subscriber }}
              </td>
            </tr>
          </template>
          <template slot="footer">
            <tr>
              <td class="text-xs-right">
                Total:
              </td>
              <td class="text-xs-center info">
                {{ totalDealer }}
              </td>
              <td class="text-xs-center info">
                {{ totalSubscriber }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </panel>
  </v-scale-transition>
</template>

<script>
import { DateTime } from 'luxon'
import { createGetParams } from '@/rest'
import apexcharts from '@/mixins/apexcharts'

const title = 'Annual User Statistics'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  mixins: [apexcharts],
  data () {
    return {
      expand: true,
      year: null,
      years: [],
      showChart: false,
      today: DateTime.local().setZone('Asia/Kuala_Lumpur').toFormat('yyyy-LL-dd'),
      breadcrumbs: [
        {
          text: 'User Statistics', disabled: false, to: '/support/user_statistics',
        },
        {
          text: title, disabled: true,
        },
      ],
      items: [],
      headers: [
        {
          text: 'Month',
          align: 'center',
          sortable: true,
          value: '',
        },
        {
          text: 'Dealer',
          align: 'center',
          sortable: true,
          value: '',
        },
        {
          text: 'Subscriber',
          align: 'center',
          sortable: 'true',
          value: '',
        },
      ],
      title: 'Annual User Statistics',
      totalDealer: 0,
      totalSubscriber: 0,
    }
  },

  watch: {
    year: function (val) {
      this.getActivations(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    const maxDate = now.toFormat('yyyy')
    for (let year = maxDate; year > 2020; year--) {
      this.years.push(year)
    }
    this.year = maxDate
    // this.chartOptions.chart.type = 'bar'
    // this.chartOptions.chart.stacked = true
    // this.chartOptions.xaxis.type = 'category'
    // this.chartOptions.xaxis.category = [
    //   'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    //   'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    // ]
    // this.chartOptions.xaxis.labels.formatter = val => this.chartOptions.xaxis.category[val - 1]
  },
  methods: {
    getActivations: async function (year) {
      const params = createGetParams({ year })
      try {
        const response = await this.$rest.get('getUserStatisticsAnnual.php', params)
        this.items = response.data.items
        this.totalDealer = response.data.totalDealer
        this.totalSubscriber = response.data.totalSubscriber
        console.log(response.data.items)
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
